


/**
 * @class Http
 */
class ConstValue {

  /**
   * @memberof ConstValue
   * @type {Number} 
   */
  static url ='';



}

export default ConstValue;